import { gql } from "@apollo/client";

export const GET_PROJECT = gql`
  query Project($assetSymbol: String!) {
    projectByAssetSymbol(assetSymbol: $assetSymbol, geoRestriction: false) {
      id
      title
      description
      status
      shortDescription
      secondDescription
      link
      logoImage
      coverImage
      startDate
      priceInUSD
      endDate
      tokensTotal
      tokensSold
      minInvestmentInUSD
      headquarters
      category
      valuationInUSD
      
      companyName
      externalLink
      foundedYear
      employees
      latestFundingRound
      lockupEndDate
      totalSharesAvailable
      investmentProductDescription
      
      projectAsset {
        address
        title
        assetId
        providerId
        displaySymbol
        baseUnit
        icon
      }
      
      projectPair {
        id
        assetIdToken0
        assetIdToken1
        balance0
        balance1
        isActive
        address
      }
    }
  }
`;
