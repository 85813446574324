export const partners = [
  {
    url: "https://ambisafe.com/",
    logo: "ambisafe_logo.svg",
    description: "One of the oldest companies in blockchain industry. Trusted by 100+ issuers and hundreds of thousands of investors."
  },
  {
    url: "https://raison.am/",
    logo: "raison_logo.svg",
    description: "Licensed investment manager with deep expertise in blockchain technology."
  },
  {
    url: "https://www.vzfirm.com/",
    logo: "zegelman_logo.svg",
    description: "Legal partner, specializing in blockchain & securities law."
  }
];

export const team = [
  {
    photo: "andrii_zamovsky.svg",
    name: "Andrii Zamovsky",
    position: "Founder and chairman of Ambisafe",
    description: "Andrii has been involved in the blockchain industry since early 2010 and has founded multiple successful blockchain companies, including Ambisafe, Orderbook and HolyTransaction. Andrii has taken leading blockchain architect role in Tether, Chronicled, Propy, Solve.Care and dozens of other blockchain technology companies.",
    mediaLinks: [
      {
        icon: "linkedin_icon.svg",
        url: "https://www.linkedin.com/in/nixoid/"
      },
      {
        icon: "telegram_icon.svg",
        url: "http://t.me/nixoid"
      },
      {
        icon: "twitter_icon.svg",
        url: "http://twitter.com/nixoid"
      }
    ]
  },
  {
    photo: "alexander_zaicev.svg",
    name: "Alexander Zaytsev",
    position: "Investment Management Adviser",
    description: "Alexander has extensive knowledge of the investment funds industry gained in some of the leading financial institutions in CIS and Europe. Prior to joining the Investment Manager, where he serves as a Director, he served as a Fund manager in RAM Data Technologies Investment PE Closed-ended Fund. Alexander is also holding a Board Member position in RSN Finance OU, Estonia, regulated FI and FinCEN registered MSB, where he is acting as Chief Compliance Officer. In 2016-2018 Alexander worked for Venture Financing Center PJCS, publicly traded company, with a role of Head of Financial Management Department.",
    mediaLinks: [
      {
        icon: "linkedin_icon.svg",
        url: "https://www.linkedin.com/in/alexander-zaitsev-3bb43614b"
      },
      {
        icon: "telegram_icon.svg",
        url: "https://t.me/zaitsev_alexander"
      },
      {
        icon: "twitter_icon.svg",
        url: "https://twitter.com/raisonapp"
      }
    ]
  },
  {
    photo: "oleksii_matiiyasevich.svg",
    name: "Oleksii Matiiasevych",
    position: "Ethereum Architect",
    description: "Oleksii is known in the blockchain industry for saving millions of dollars during the Parity Wallet hack and for identifying vulnerabilities in 12+ top-rated cryptocurrency exchanges. He has worked with dozens of blockchain projects including Tether, Bitso, Propy, Remme, and ChainSafe. Having developed hundreds of smart-contracts and Ethereum applications, Oleksii is currently the lead smart contracts architect at Ambisafe. Oleksii is responsible for architecture and maintenance of the token smart contracts and related blockchain infrastructure.",
    mediaLinks: [
      {
        icon: "linkedin_icon.svg",
        url: "https://www.linkedin.com/in/lastperson/"
      },
      {
        icon: "telegram_icon.svg",
        url: "https://t.me/lastperson"
      },
    ]
  },
  {
    photo: "julian_zegelman.svg",
    name: "Julian Zegelman",
    position: "Experienced corporate attorney",
    description: "Julian is an experienced corporate attorney, serial entrepreneur and angel investor.  He previously served as a Managing Partner at Velton Zegelman PC, a boutique corporate and securities law firm with offices in Silicon Valley. Earlier in his career, Julian was a co-founder and General Counsel of Rolith, Inc. where he focused on fundraising, corporate development, and legal affairs.  During his tenure at Rolith, Julian raised multiple rounds of venture capital financing, completed strategic technology deals with large corporate partners, and negotiated inbound and outbound licensing of intellectual property.  Rolith was acquired by MetaMaterial Technologies, Inc. in May 2016. ",
    mediaLinks: [
      {
        icon: "linkedin_icon.svg",
        url: "https://www.linkedin.com/in/jzegelman/"
      },
      {
        icon: "telegram_icon.svg",
        url: "http://t.me/zegelman"
      },
      {
        icon: "twitter_icon.svg",
        url: "https://twitter.com/jzegelman"
      }
    ]
  }
];
